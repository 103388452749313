.footer-container {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(4, 12, 24, 1) 0%,
    rgba(0, 40, 83, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(4, 12, 24, 1) 0%,
    rgba(0, 40, 83, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(4, 12, 24, 1) 0%,
    rgba(0, 40, 83, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(4, 12, 24, 1) 0%,
    rgba(0, 40, 83, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(4, 12, 24, 1) 0%,
    rgba(0, 40, 83, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
  padding: 0 20px 0 20px;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 1.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 10px auto 0 auto;
}

.social-icons {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 64px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .info-text-footer {
    font-size: 10px !important;
  }

  .info-text-footer-contact {
    font-size: 12px !important;
  }

  .footer-link-items {
    margin: 2px;
  }

  .social-media-wrap {
    margin-top: 10px;
  }

  .footer-map-container {
    width: 100%;
    padding: 20px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .social-logo {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 768px) {
}

.info-text-footer {
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  padding-left: 10px;
}

.info-text-footer-contact {
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  min-height: 50px;
}

.footer-logo-img {
  max-block-size: 80px;
  margin-top: 40px;
}

.footer-map-container {
  width: 100%;
  padding: 20px;
  padding-left: 100px;
  padding-right: 100px;
}

.footer-map-text {
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 35px;
}
