.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  z-index: 999;
  flex: 1;
  margin: 0;
  padding: 0;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 2000px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-right: 80px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 50vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links-selected {
  color: #cf0101;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #cf0101;
  transition: all 0.1s ease-out;
}

.fa-bars {
  color: #fff;
  margin-bottom: -100px;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbar-logo-img {
  max-block-size: 80px !important;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navbar-logo-img {
    max-block-size: 60px !important;
  }

  .nav-menu.active {
    background: linear-gradient(
      90deg,
      rgb(28, 27, 27) 0%,
      rgb(26, 23, 23) 100%
    );
    /* ff 3.6+ */
    background: -moz-radial-gradient(
      circle at 3% 25%,
      rgba(0, 40, 83, 1) 0%,
      rgba(4, 12, 24, 1) 100%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(
      circle at 3% 25%,
      rgba(0, 40, 83, 1) 0%,
      rgba(4, 12, 24, 1) 100%
    );

    /* opera 11.10+ */
    background: -o-radial-gradient(
      circle at 3% 25%,
      rgba(0, 40, 83, 1) 0%,
      rgba(4, 12, 24, 1) 100%
    );

    /* ie 10+ */
    background: -ms-radial-gradient(
      circle at 3% 25%,
      rgba(0, 40, 83, 1) 0%,
      rgba(4, 12, 24, 1) 100%
    );

    /* global 92%+ browsers support */
    background: radial-gradient(
      circle at 3% 25%,
      rgba(0, 40, 83, 1) 0%,
      rgba(4, 12, 24, 1) 100%
    );

    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding: 40px;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #cf0101;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    max-block-size: 60px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 100%);
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

.wallpaper-container {
  background: url("/public/images/AdobeStock_231701917.png") center center/cover
    no-repeat;
  height: 12vh;
  width: 100%;
  margin-bottom: 50px;
}

.wallpaper-container-footer {
  background: url("/public/images/AdobeStock_231701917.png") center center/cover
    no-repeat;
  height: 12vh;
  width: 100%;
}
