.pages-container {
  min-height: 1000px;
  padding-top: 40px;
}

.about-us-container {
  padding: 60px;
}

@media screen and (max-width: 960px) {
  .about-us-container {
    padding: 10px;
  }
}
