.header {
  display: flex;
}

.child-component {
  flex: 1;
  margin: 0;
  padding: 0;
  /* Additional styling for the child components */
}
