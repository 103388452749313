/* DragPictureEffect.css */

/* Add styles for the header */
.header {
  display: block !important;
  position: absolute;
  top: 20%;
  left: 5%;
  width: 40%;
  text-align: center;
  color: white;
  font-size: 60px;
  line-height: 1.5;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
}

.header p {
  margin: 0;
}

/* Style the paragraphs to display on separate rows */
.header p {
  display: block;
}

/* Ensure the text is visible on all pictures */
.slider .picture {
  position: relative;
}

.drag-picture-container {
  overflow: hidden;
  width: 100%;
  max-height: 700px; /* Adjust the height as needed */
}

.slider {
  display: flex;
  transition: transform 1s ease-in-out;
}

.picture {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button-container {
  padding: 10px;
}

@media screen and (max-width: 1200px) {
  .header {
    display: block !important;
    position: absolute;
    top: 20%;
    left: 30%;
    width: 30%;
    text-align: center;
    color: white;
    font-size: 40px;
    line-height: 1;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  }
}

@media screen and (max-width: 760px) {
  .header {
    display: block !important;
    position: absolute;
    top: 20%;
    left: 30%;
    width: 30%;
    text-align: center;
    color: white;
    font-size: 20px;
    line-height: 0.8;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  }
}

.spiner-container {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  display: flex;
  justify-content: center;
  min-height: 80vh;
}
