.map-container {
  padding: 50px;
  width: 100%;
  height: 30vh;
}

@media screen and (max-width: 820px) {
  .map-container {
    height: 20vh;
    padding: 25px;
  }
}
